import React, {
  FunctionComponent,
  useCallback,
  useEffect,
  useState,
} from "react"
import { Helmet as Head } from "react-helmet"
import ThreeColumnLayout from "../components/layout/three-column-layout"
import { currencies } from "../etc/currencies"
import { exactDate } from "../services/date"

const RatesPage: FunctionComponent = () => {
  const [updateTime, setUpdateTime] = useState(0)
  const [rates, setRates] = useState([])

  const fetchRates = useCallback(async () => {
    const response = await Promise.resolve(
      fetch("/api/v2/rates")
        .then(res => res.json())
        .catch(error => {
          console.log("json parsing error occured ", error)
          return null
        })
    )
    if (response) {
      setUpdateTime(Math.floor(response.ts / 1000))
      let r = currencies.map(c => {
        //if (response[c.c]) console.log(response[c.c])
        return { c: c.c, n: c.n, r: response.rates[c.c] }
      })
      if (r.length) setRates(r)
    }
  }, [])

  useEffect(() => {
    fetchRates()
  }, [])

  return (
    <>
      <Head>
        <title>Smartlike</title>
        <meta name="description" content="Smartlike p2p donations" />
        <meta property="og:title" content="Smartlike" />
        <meta property="og:description" content="Smartlike p2p donations" />
      </Head>

      <ThreeColumnLayout>
        {{
          content: (
            <div style={{ fontFamily: "Roboto", marginBottom: "20px" }}>
              {updateTime != 0 && (
                <>
                  <div className="header">Currency Exchange Rates</div>

                  <div className="intro">
                    Smartlike transfers are automatically converted to preferred
                    recipient's currency. Conversion is performed in double
                    floating point precision without commission.
                    <br />
                    The rates are regularly set by user consensus.
                    <span> Last updated at {exactDate(updateTime)}.</span>
                  </div>

                  <div>
                    <table style={{ margin: "auto", width: "80%" }}>
                      {rates.length != 0 && (
                        <thead style={{ fontWeight: 400, textAlign: "center" }}>
                          <tr>
                            <td style={{ textAlign: "left" }}>code</td>
                            <td style={{ textAlign: "left" }}>currency</td>
                            <td
                              className="tcell"
                              style={{
                                textAlign: "right",
                                paddingBottom: "10px",
                              }}
                            >
                              rate to USD
                            </td>
                          </tr>
                        </thead>
                      )}

                      {rates.map(c => (
                        <tr>
                          <td>{c.c}</td>
                          <td>{c.n}</td>
                          <td className="rate">{c.r}</td>
                        </tr>
                      ))}
                    </table>
                  </div>
                </>
              )}
            </div>
          ),
          sidebar: null,
        }}
      </ThreeColumnLayout>
      <style jsx>{`
        .header {
          text-align: center;
          font-size: 18px;
          margin-bottom: 10px;
        }
        .intro {
          margin-bottom: 20px;
          line-height: 22px;
          /*text-align: justify;*/
          padding-left: 5px;
          padding-right: 5px;
        }

        .ttable {
          display: table-row;
        }
        .trow {
          display: table-row;
        }
        .tcell {
          display: table-cell;
          padding: 2px;
        }
        .rate {
          padding: 2px;
          text-align: right;
          font-family: monospace;
          font-size: 14px;
        }
      `}</style>
    </>
  )
}
export default RatesPage
